<template>
  <b-nav-item-dropdown
    ref="notDropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.filter(notif => notif.lido === false).length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notificações
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.filter(notif => notif.lido === false).length }} Novas
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="(notification, indexNot) in notifications.filter(notif => notif.lido === false)"
        :key="indexNot"
        :class="notification.erroTipo != 'ARQUIVO_CLIENTE' && notification.erroTipo != 'CEP_INVALIDO' ? 'nao-clicavel' : ''"
        :to="notification.erroTipo == 'ARQUIVO_CLIENTE' || notification.erroTipo == 'CEP_INVALIDO' ? { name: 'geracao-etiquetas-importacao', params: { redirecionado: true, layoutId: notification.layoutId } } : null"
        @click="notification.erroTipo == 'ARQUIVO_CLIENTE' || notification.erroTipo == 'CEP_INVALIDO' ? hideNotifications() : ''"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <small v-if="notification.data" class="notification-text">
            {{ maskDateHour(notification.data) }}
          </small>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ `${notification.title} - ${categoriaErro(notification.erroTipo, notification.layoutId)}` }}
            </span>
          </p>
          <small v-if="notification.erroTipo === 'IMPORTACAO_FTP'">
            <small
              v-for="(line, index) in notification.subtitle"
              :key="'Linha-' +index"
              class="notification-text"
            >{{ line }}
              <br>
            </small>
          </small>
          <small 
            v-else 
            class="notification-text"
          >{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-if="notifications.filter(notif => notif.lido === false).length > 0"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="limpar"
      >Limpar notificações
      </b-button>
      <span v-else>Não há novas notificações</span>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { toRefs, reactive, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import Utils from '@/helpers/utils'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const errosStore = createNamespacedHelpers('movimentacaoErro')
    const state = reactive({
      notifications: errosStore.useGetters(['movimentacaoErro']).movimentacaoErro,
    })
    
    const notDropdown = ref(null)

    const categoriaErro = (erroTipo, layoutId) => {
      let tipoArquivo
      switch (layoutId) {
        case -3: tipoArquivo = 'NOTFIS 3.1'
          break
        case -4: tipoArquivo = 'NOTFIS 5.0'
          break
        case -5: tipoArquivo = 'IMPORTAÇAO FTP'
          break
        default: tipoArquivo = 'XML'
          break
      }
      switch (erroTipo) {
        case 'API':
          return 'Erro na API Jadlog'
        case 'INTERNO':
          return 'Erro no sistema Portal Envios'
        case 'CADASTRO_CLIENTE':
          return 'Erro no cadastro do cliente'
        case 'CLIENTE_SEM_SMART_LABEL':
          return 'Erro de Smart Label'
        case 'IMPORTACAO_FTP':
          return 'Envios com erro para geração'
        default:
          return `Erro no arquivo ${tipoArquivo}`
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      minScrollbarLength: 60,
      wheelPropagation: false,
    }

    const limpar = () => {
      const { clienteId } = JSON.parse(localStorage.getItem('userName'))
      store.dispatch('movimentacaoErro/lerTodas', clienteId)
        .then(() => {
          store.dispatch('movimentacaoErro/findAllByClienteId', clienteId)
            .then(erros => {
              state.notifications.value = erros
            })
        })
    }

    const hideNotifications = () => {
      notDropdown.value.hide()
    }

    return {
      ...toRefs(state),
      ...Utils,
      perfectScrollbarSettings,
      categoriaErro,
      limpar,
      notDropdown,
      hideNotifications,
    }
  },
}
</script>

<style>
.nao-clicavel {
  cursor: default!important;
}
.nao-clicavel .media:hover {
  background-color: transparent!important;
}
</style>
